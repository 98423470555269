#root {
  height: 100vh;
}

p {
  font-size: 18px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 60px;
  row-gap: 0px;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  overscroll-behavior: none; /* Prevent bounce effects */
}

.empty-space {
  min-height: 50px;
}

.legal-bar {
  display: flex;
  justify-content: space-between;
  color: #555;
  padding: 0 10px;
}

.imprint-link {
  margin-right: 15px;
  color: #555;
  cursor: pointer;
}

.jonas-link {
  color: #555;
}

.imprint-modal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 5px 15px 0 hsl(0deg 0% 44% / 51%);
  border-radius: 20px;
  width: 300px;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
}

.imprint-content {
  text-align: left;
  flex: 1;
  margin: 10px 25px 25px 25px;
}

.imprint-sun-icon {
  height: 50px;
  margin-bottom: 20px;
}

.imprint-close-icon {
  margin: 25px 25px 0 auto;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.right-column-spacer-1,
.left-column-spacer-1 {
  height: 20vh;
}

.right-column-spacer-2 {
  height: 10vh;
}

@media (max-width: 850px) {
  .App {
    height: auto;
    overflow-y: auto;
  }

  .container {
    flex-direction: column;
    height: auto;
    overflow: visible;
    padding: 20px;
    min-height: unset;
  }

  .mobile-container {
    padding-top: 0;
  }

  .left-column,
  .right-column {
    width: 100%;
    height: auto;
    overflow: visible;
  }

  .main-image-container {
    max-width: 200px;
    margin: 0 auto;
  }

  .main-image {
    margin: 10px;
    margin-bottom: 30px;
  }

  .contact-information {
    margin-top: 60px;
  }

  .mobile-imprint {
    text-align: center;
    margin: 40px 0;
    cursor: pointer;
    color: #666;
  }

  .right-column-spacer-1,
  .right-column-spacer-2 {
    display: none;
  }

  p {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

@media (max-width: 500px) {
  .container,
  .left-column {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .project-list {
    margin-top: 20px !important;
    margin-bottom: 50px !important;
  }
  .right-column {
    min-width: unset !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .information-tabs-header-mobile-selected,
  .information-tabs-header-mobile {
    display: flex !important;
    cursor: pointer;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
  .information-tabs-header-desktop-text {
    margin-left: 10px;
  }
  .information-tabs-header-desktop {
    display: none;
  }
}

.information-tabs-header-mobile-selected {
  -webkit-filter: invert(30%) sepia(69%) saturate(3758%) hue-rotate(192deg) brightness(94%) contrast(98%);
  filter: invert(30%) sepia(69%) saturate(3758%) hue-rotate(192deg) brightness(94%) contrast(98%);
}

.icon-black-to-gray {
  opacity: 0.7;
}

.information-tabs-header-mobile-selected,
.information-tabs-header-mobile {
  display: none;
}

.left-column {
  flex: 1;
  max-width: 280px;
  min-width: 240px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.main-image {
  width: 100%;
  margin: 20px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.right-column {
  flex: 2;
  margin: 0px 20px;
  color: #333;
  max-width: 460px;
  min-width: 460px;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.right-column::-webkit-scrollbar {
  display: none;
}

.h1-custom {
  font-size: 26px;
  font-weight: 500;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;
}

.project-link {
  text-decoration: none;
  color: inherit;
}

.project-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 6px;
  gap: 16px;
  flex-wrap: wrap;
  transition: all 0.2s ease-in-out;
}

.project-container:hover {
  cursor: pointer;
}

.project-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 10px;
}

.project-image-border {
  border: 2px solid #273f52;
}

.project-description-1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  min-width: 200px;
}

.project-title {
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.project-description {
  font-size: 14px;
  font-weight: 400;
}

.project-description-2 {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  flex-shrink: 0;
}

.h3-custom {
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto 0 0;
}

.information-tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 200px;
}

.information-tabs-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.information-tabs-header-desktop-selected {
  color: #0275d6;
  font-weight: 700;
}

.information-tabs-content {
  border-top: 1px solid #333;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding-top: 40px;
  text-align: left;
}

.content-prefix {
  margin-bottom: 15px;
}

.content-prefix-image {
  height: auto;
  width: 30px;
  margin-right: 10px;
}

.content-prefix-image-small {
  height: 25px;
  width: 25px;
  margin-right: 15px;
  margin-bottom: 5px;
}

.content-list-item-text {
  color: #555;
}

.fi-list {
  margin-top: 10px;
  margin-bottom: 35px;
  list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='-1 -1 3 3'><circle r='0.7' /></svg>");
}

.content-list-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
}

.fa-custom {
  margin-right: 10px;
  width: 30px;
  text-align: center;
}

.contact-information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.project-snacks {
  list-style-type: none;
  padding-left: 20px;
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 14px;
  color: #333;
}

.project-snacks li {
  margin-bottom: 4px;
  position: relative;
}

.project-snacks li:before {
  content: "→";
  position: absolute;
  left: -20px;
  color: #666;
}
